export function getStageLabel(stage: TRoomStages): string | null {
	const labels: Record<TRoomStages, string> = {
		search_participants: "Поиск участников",
		fix_conditions: "Фиксирование условий",
		contract_signing: "Заключение договора",
		contract_execution: "Исполнение договора",
		settlement_with_participants: "Расчёт с участниками",
	};

	return labels[stage] || null;
}
